main{
    display:flex;
    flex-direction:row;
  }
  .round{
    display:flex;
    flex-direction:column;
    /*justify-content:center;*/
    width:200px;
    list-style:none;
    padding:0;
  }
    .round .spacer{ flex-grow:.5; }
    .round .spacer:first-child,
    .round .spacer:last-child{ flex-grow:.5; }
  
    .round .game-spacer{
      flex-grow:.5;
    }
  
  /*
   *  General Styles
  */
  
  li.game{
    padding-left:20px;
  }
  
    li.game.winner{
      font-weight:bold;
    }
    li.game span{
      float:right;
      margin-right:5px;
    }
  
    li.game-top{ border-bottom:1px solid #aaa; }
  
    li.game-spacer{ 
      border-right:1px solid #aaa;
      min-height:40px;
    }
  
    li.game-bottom{ 
      border-top:1px solid #aaa;
    }


    .packages-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.5); /* Overlay background */
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5000;
    }
    
    .packages-modal {
      background-color: #fff;
      border-radius: 5px;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 80%;
      max-width: 600px;
    }
    
    .close-btn {
      background: none;
      border: none;
      font-size: 20px;
      cursor: pointer;
    }
    
    .packages-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    
    .package {
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 15px;
      margin: 10px;
      flex: 1 0 calc(33% - 20px);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      text-align: center; /* To align items horizontally */
      position: relative;
    }
    
    .package h3,
    .package p {
      margin: 5px 0;
      color: black;
    }
    
    .package:hover {
      background-color: #f5f5f5;
    }
    
    .select-button {
      background: #007bff;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 10px 15px;
      cursor: pointer;
      position: absolute;
      bottom: 15px;
      right: 15px;
    }
    

    .loginregister-modal-bg {
      background-color: #080e37 !important;
    }